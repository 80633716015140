import React, { useCallback, useState, useMemo, useRef, Fragment, memo } from 'react';
import { FormControl } from '@mui/material';
import DatePicker from 'react-datepicker';
import PropTypes from 'prop-types';
import 'react-datepicker/dist/react-datepicker.css';
import classNames from 'common/class-names';

const AppDateInput = ({
    onChange, onClear, value, defaultValue, label, required, error, touched, className, disabled, multiline, name, placeholder, dateFormat, type, minDate, ...props
}) => {

  function parseDateString(dateString) {
    if (!dateString) return null;

    let day, month, year;
    if (dateString.includes('/')) {
      // Format: dd/mm/yyyy
      [day, month, year] = dateString.split('/').map(part => parseInt(part, 10));
    } else if (dateString.includes('-')) {
      // Format: yyyy-mm-dd
      [year, month, day] = dateString.split('-').map(part => parseInt(part, 10));
      // Add a day to the date
      day += 1;
    } else {
      console.error("Invalid date format");
      return null;
    }

    // Create the date object
    const dateObject = new Date(year, month - 1, day);

    // Handle month overflow if day exceeds the number of days in the month
    if (dateObject.getDate() !== day) {
      dateObject.setDate(0); // Set to the last day of the previous month
    }
    
    return dateObject;
  }

  
  // Convert value to Date object if it's a string
  const selectedDate = useMemo(() => {
    return typeof value === 'string' ? parseDateString(value) : value;
  }, [value]);
  
    //const [selectedDate, setSelectedDate] = useState(value || null);
    const textFieldRef = useRef();
    const isErrorField = useMemo(() => !!error && !!touched, [error, touched]);
    const errorMessage = useMemo(() => (isErrorField ? error : ''), [error, isErrorField]);
    const combinedClassName = useMemo(() => {
      return classNames({
        'app-input': true,
        'app-input--disabled': disabled,
        'app-input--multiline': multiline,
        [className]: !!className,
      });
    }, [className, disabled, multiline]);


    // Handle the change event, including updating the selected date and invoking the onChange callback.
    const onHandleChange = useCallback((date) => {
      //setSelectedDate(date);
      //onChange({ target: { value: date, name } });

      if (onChange) {
        const formattedDate = date ? date.toISOString().split('T')[0] : '';
        onChange({ target: { value: formattedDate, name } });
      }
    }, [onChange, name]);

     


    const Footer = useCallback(() => (
      <Fragment>
        {errorMessage && <div className="app-date-input__error">{errorMessage}</div>} 
        {props.multiline && <span className="app-input__count">{`${props.value.length}/${props.maxLength}`}</span>}
      </Fragment>
    ), [errorMessage, props.multiline, props.value, props.maxLength]);


    return (
      <div className={combinedClassName}>
        <FormControl error={isErrorField}>
          <label htmlFor={name} className="app-date-input__label">
            {label}
            {required && <span className="app-date-input__required">*</span>}
          </label>
          <DatePicker
            id={type}
            selected={selectedDate}
            onChange={onHandleChange}
            dateFormat={dateFormat}
            placeholderText={placeholder}
            ref={textFieldRef}
            minDate={minDate}
            closeOnScroll={true}
            disabled={disabled}
            {...props}

            popperPlacement='bottom-center'
          />
          {Footer()}
        </FormControl>
        
      </div>
    );
};

AppDateInput.propTypes = {
    error: PropTypes.string,
    label: PropTypes.string,
    disabled: PropTypes.bool,
    required: PropTypes.bool,
    className: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onClear: PropTypes.func,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    dateFormat: PropTypes.string,
    isClearable: PropTypes.bool,
    minDate: PropTypes.func,
};

AppDateInput.defaultProps = {
    disabled: false,
    required: false,
    className: '',
    value: null,
    onClear: null,
    type: 'text',
    placeholder: '',
    dateFormat: 'dd/MM/yyyy',
    touched: false,
    error: '',
    isClearable: false,
};

export default memo(AppDateInput);
