import pathnames from "routes/pathnames";

import AppLayout from "components/app-layout";
import PageHome from "pages/travel/page-home";
import PageAppStore from "pages/travel/page-app-store";
import PagePaForm from "pages/travel/page-pa-form";
import PageBeForm from "pages/travel/page-beneficiaries-form";

const routes = [
	{
		path: pathnames.home,
		element: <PageHome />,
	},
	{
		path: pathnames.appStore,
		element: <PageAppStore />,
	},
	{
		path: pathnames.paForm,
		element: <PagePaForm />,
	},
	{
		path: pathnames.beForm,
		element: <PageBeForm />,
	},
];

const enrichedRouters = routes.map(({ ...res }) => {
	return { ...res, element: <AppLayout>{res.element}</AppLayout> };
});

export default enrichedRouters;
