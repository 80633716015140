import React, { useMemo, useCallback, Fragment, useState } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import COMMON from "common";
//import api from "services/api";
import ERRORS from "common/errors";
import pathnames from "routes/pathnames";
import serveRequestErrors from "common/serve-request-errors";
import formatInputString from "common/format-input-string";
import formatPassportString from "common/format-passport-string";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppSelectInput from "components/app-select-input";
import AppMaskingInput from "components/app-masking-input";
import AppLoading from "components/pages/page-enquiry/app-loading";
import AppCardStatus from "components/pages/page-enquiry/app-card-status";
import getIdentificationListing from "services/get-identification-listing";
import getCountryListing from "services/get-country-listing";
import AppMobileInput from "components/app-mobile-input";
import AppDateInput from "components/app-date-input";
import { setBeneficiariesForm } from "store/slices/form";

const PageBeForm = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const form = useSelector((state) => state.form);
	const [messageType, setMessageType] = useState("");
	const [errorCode] = useState("");
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const referralCode = urlParams.get("myReferralCode") || "";

	const initialValues = useMemo(() => {
		const info = form?.benefiForm;
		const isInfoPassport = info?.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT;

		let payload = {
			fullName: "",
			identificationType: "NRIC",
			passport: "",
			nric: process.env.REACT_APP_ID,
			dob: "",
			nationality: "",
			mobileNo: "",
			prefixNo: "",
			privacy: false,
			tnc: false,
		};

		if (info) {
			payload.tnc = true;

			payload.privacy = true;

			if (info.identificationType) payload.identificationType = info.identificationType;

			if (info.identificationNo) {
				if (isInfoPassport) {
					payload.passport = info.identificationNo;
				} else {
					payload.nric = info.identificationNo;
				}
			}

			if (info.fullName) payload.fullName = info.fullName;

			if (info.dob) payload.dob = info.dob;

			if (info.nationality) payload.nationality = info.nationality;
			
			if (info.mobileNo) payload.mobileNo = info.mobileNo;
			
			if (info.prefixNo) payload.prefixNo = info.prefixNo;
		}

		return payload;
	}, [form]);

	const formik = useFormik({
		initialValues,
		validationSchema: yup.object({
			fullName: yup.string().required(ERRORS.REQUIRED),
			identificationType: yup.string().required(ERRORS.REQUIRED),
			passport: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT,
				then: () => yup.string().required(ERRORS.REQUIRED),
			}),
			nric: yup.string().when(["identificationType"], {
				is: (identificationType) => identificationType === COMMON.IDENTIFICATION_TYPE.NRIC,
				then: () => yup.string().required(ERRORS.REQUIRED).min(14, ERRORS.NRIC),
			}),
			dob: yup.string().required(ERRORS.REQUIRED),
			nationality: yup.string().required(ERRORS.REQUIRED),
			mobileNo: yup.string().required(ERRORS.REQUIRED).min(9, ERRORS.MOBILE),
			tnc: yup.boolean().oneOf([true], ERRORS.REQUIRED),
			privacy: yup.boolean().oneOf([true], ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});


	const isPassport = useMemo(() => formik.values.identificationType === COMMON.IDENTIFICATION_TYPE.PASSPORT, [formik.values.identificationType]);
	const validIdentificationType = useMemo(() => !!formik.values.identificationType, [formik.values.identificationType]);

	const onHandleNavigateQuotation = useCallback(() => {
		navigate(pathnames.quotation);
	}, [navigate]);

	const onHandleSubmit = async (values) => {
		//let response = null;

		try {
			let payload = {
				fullName: values.fullName,
				identificationType: values.identificationType,
				identificationNo: isPassport ? values.passport : values.nric,
				//dob: isPassport ? null : getDateOfBirthNRIC(values.nric),
				dob: values.dob,
				nationality: values.nationality,
				mobileNo: values.prefixNo + values.mobileNo,
				referralCode: referralCode,
			};
			dispatch(setBeneficiariesForm(payload));
			//if (referralCode && referralCode !== "null") dispatch(setReferralCode(referralCode));
			//response = await api.post.motor.quotation(payload);
		} catch (error) {
			
			if (!error?.response?.data?.errorCode) {
				if (error?.code === COMMON.ERRORS.NETWORK_ERROR) {
					setMessageType(COMMON.MESSAGE_TYPE.NETWORK);
				} else if (error?.code === COMMON.ERRORS.BE_BAD_RESPONSE) {
					setMessageType(COMMON.MESSAGE_TYPE.INVALID);
				}
			} else {
				serveRequestErrors(error);
			}
		} finally {
			formik.setSubmitting(false);
		}

		onHandleNavigateQuotation();
	};

	//prettier-ignore
	const onHandleTnc = useCallback((event) => {
		const value = event.currentTarget.checked
		formik.setFieldValue("tnc", !value);
	}, [formik]);

	//prettier-ignore
	const onHandlePrivacy = useCallback((event) => {
		const value = event.currentTarget.checked
		formik.setFieldValue("privacy", !value);
	}, [formik]);

	//prettier-ignore
	const PrivacyLabel = useCallback(() => {
		return (
			<Fragment>
				I consent to the<a className="enquiry__tnc-pdf" href="https://www.easycover.my/privacy-policy-2/" target="_blank" rel="noreferrer"> Data Protection and Privacy Policy</a>.<span className="enquiry__tnc-required">*</span>
			</Fragment>
		)
	}, []);

	//prettier-ignore
	const TncLabel = useCallback(() => {
		return (
			<Fragment>
				I have read <a className="enquiry__tnc-pdf" href="https://www.easycover.my/terms/" target="_blank" rel="noreferrer">Terms and Conditions</a> together with <a className="enquiry__tnc-pdf" href="https://www.easycover.my/FAQ/" target="_blank" rel="noreferrer">FAQ</a> herein and fully understand and agree with the said Terms and Conditions and FAQ.<span className="enquiry__tnc-required">*</span>
			</Fragment>
		)
	}, []);

	const IdentificationField = useCallback((obj) => {
		if (!obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppMaskingInput required type="text" name="nric" label="NRIC No." placeholder="e.g. 901010-14-1234" disabled={obj.disabled} value={obj.values.nric} error={obj.errors.nric} touched={obj.touched.nric} onChange={obj.onChange} format={COMMON.MASKING.NRIC} />
		}

		if (obj.isPassport && obj.validIdentificationType) {
			/* prettier-ignore */
			return <AppInput required type="text" name="passport" label="Passport" placeholder="e.g. VD1289281" disabled={obj.disabled} value={obj.values.passport} error={obj.errors.passport} touched={obj.touched.passport} onFormat={formatPassportString} onChange={obj.onChange} />
		}

		return null;
	}, []);



	return (
		<div className="page-pa-form">
			<div className="enquiry">
				<form className="enquiry__form" onSubmit={formik.handleSubmit}>
					<div className="enquiry__container">
						<div className="enquiry__box">
							{!messageType && !formik.isSubmitting && (
								<div className="enquiry__wrapper">
									<h1 className="enquiry__title">Beneficiaries Details</h1>
									<p className="enquiry__description">Submit your information to apply for personal insurance.</p>

									<div className="enquiry__box-body">

										{/* prettier-ignore */}
										<AppInput required type="text" name="fullName" label="Full Name" placeholder="eg: John Smith" value={formik.values.fullName} error={formik.errors.fullName} touched={formik.touched.fullName} onChange={formik.handleChange} disabled={formik.isSubmitting} onFormat={formatInputString} />

										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="identificationType" label="ID Type" placeholder="Please Select" loadOptions={getIdentificationListing} value={formik.values.identificationType} error={formik.errors.identificationType} touched={formik.touched.identificationType} onChange={formik.handleChange} disabled={formik.isSubmitting} searchable={false} />

										{/* prettier-ignore */}
										<IdentificationField values={formik.values} errors={formik.errors} touched={formik.touched} onChange={formik.handleChange} disabled={formik.isSubmitting} isPassport={isPassport} validIdentificationType={validIdentificationType} />

										{/* prettier-ignore */}
										<AppDateInput required type="date-picker" name="dob" label="Date of Birth" placeholder="eg: 01/01/2024" value={formik.values.dob} error={formik.errors.dob} touched={formik.touched.dob} onChange={formik.handleChange}  disabled={formik.isSubmitting} />
										
										{/* prettier-ignore */}
										<AppSelectInput required type="text" name="nationality" label="Nationality" placeholder="Please Select" loadOptions={getCountryListing} value={formik.values.nationality} error={formik.errors.nationality} touched={formik.touched.nationality} onChange={formik.handleChange} disabled={formik.isSubmitting} searchable={true} />

										{/* prettier-ignore */}
										<AppMobileInput required maxLength={12} type="text" name="mobileNo" prefixNoName="prefixNo" label="Contact Number" placeholder="eg: 121231234" value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} disabled={formik.isSubmitting} />
										
										{/* prettier-ignore */}
										<AppCheckbox required name="tnc" onClick={onHandleTnc} label={<TncLabel />} error={formik.errors.tnc} value={formik.values.tnc} touched={formik.touched.tnc} disabled={formik.isSubmitting} />

										<AppCheckbox required name="tnc" onClick={onHandlePrivacy} label={<PrivacyLabel />} error={formik.errors.privacy} value={formik.values.privacy} touched={formik.touched.privacy} disabled={formik.isSubmitting} />

										
									</div>

									<div className="enquiry__button-container">
										<AppButton type="submit" label="Submit" disabled={formik.isSubmitting} />
									</div>
								</div>
							)}

							<AppLoading isLoading={formik.isSubmitting} text="Processing page during submiting your form." />

							<AppCardStatus messageType={messageType} error={errorCode} setMessageType={setMessageType} />
						</div>
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageBeForm;
