import React, { useEffect, useState, useRef, useMemo } from 'react';
import Tesseract from 'tesseract.js';
import classNames from "common/class-names";
import PropTypes from "prop-types";


const TextRecognition = (props, onExtractData) => {
  const [selectedImage, setSelectedImage] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [icNumber, setIcNumber] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const containerRef = useRef(null);
  const [ocrComplete, setOcrComplete] = useState({ ic: false, name: false, address: false });


  const className = useMemo(() => {
		return classNames({
			"app-id-scan": true,
			"app-id-scan--outline": props.outline || false,
			...(props.className && {
				[props.className]: true,
			}),
		});
	}, [props.className, props.outline]);

  const addWatermark = (ctx, text, width, height) => {
    ctx.font = "50px Arial";
    ctx.fillStyle = "rgba(0, 0, 0, 3)";
    ctx.textAlign = "right";
    ctx.fillText(text, width - 10, height - 10);
  };

  const handleImageUpload = (event) => {
    const image = event.target.files[0];
    const maxFileSize = 2 * 1024 * 1024; // 2 MB
    const maxWidth = 1920; // Maximum width in pixels
    const maxHeight = 1080; // Maximum height in pixels
  
    if (image.size > maxFileSize) {
      alert("File size exceeds 2 MB. Please upload a smaller image.");
      return;
    }
  
    
    const img = new Image();
    img.src = URL.createObjectURL(image);
    img.onload = () => {
      if (img.width > maxWidth || img.height > maxHeight) {
        alert(`Image dimensions exceed ${maxWidth}x${maxHeight} pixels. Please upload a smaller image.`);
        return;
      }

      // Create a canvas to draw the image and watermark
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.width = img.width;
      canvas.height = img.height;

      // Draw the image on the canvas
      ctx.drawImage(img, 0, 0);

      // Add watermark
      addWatermark(ctx, "For easycover.my use only", img.width, img.height);

      // Convert the canvas to a data URL and set it as the selected image
      setSelectedImage(canvas.toDataURL('image/jpeg'));
      //setSelectedImage(img.src);
    };
    
  };
  


  const handleOCR = (image, coords) => {
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.src = image;
    img.onload = () => {
      const container = containerRef.current;
      const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();

      // Set canvas size to container size
      canvas.width = containerWidth;
      canvas.height = containerHeight;

      // Draw the image scaled to fit the container
      ctx.drawImage(img, 0, 0, containerWidth, containerHeight);

      // Add watermark
      addWatermark(ctx, "EasyCover", containerWidth, containerHeight);

      // Use container-based coordinates for cropping
      const croppedImage = ctx.getImageData(coords.x, coords.y, coords.width, coords.height);
      const tempCanvas = document.createElement('canvas');
      tempCanvas.width = coords.width;
      tempCanvas.height = coords.height;
      tempCanvas.getContext('2d').putImageData(croppedImage, 0, 0);

      const croppedImageDataUrl = tempCanvas.toDataURL('image/jpeg');

      setIsProcessing(true);
      setOcrComplete(prev => ({ ...prev, ic: false }));
      setOcrComplete(prev => ({ ...prev, name: false }));
      setOcrComplete(prev => ({ ...prev, address: false }));

      Tesseract.recognize(
        croppedImageDataUrl,
        'eng',
        { logger: (m) => console.log(m) }
      ).then(({ data: { text } }) => {
        if (coords.label === 'IC') {
          setIcNumber(text.trim());
          setOcrComplete(prev => ({ ...prev, ic: true }));
        }
        if (coords.label === 'Name') {
          setName(text.trim());
          setOcrComplete(prev => ({ ...prev, name: true }));
        }
        if (coords.label === 'Address') {
          setAddress(text.trim());
          setOcrComplete(prev => ({ ...prev, address: true }));
        }
      })
    };
  };

  


  useEffect(() => {
    const processImage = (() => {
      if (!selectedImage || !containerRef.current) return;
      const container = containerRef.current;
      const { width: containerWidth, height: containerHeight } = container.getBoundingClientRect();
  
      // Define coordinates based on container size
      const icCoords = { x: containerWidth * 0.01, y: containerHeight * 0.17, width: containerWidth * 0.48, height: containerHeight * 0.15, label: 'IC' };
      const nameCoords = { x: containerWidth * 0.02, y: containerHeight * 0.60, width: containerWidth * 0.25, height: containerHeight * 0.21, label: 'Name' };
      const addressCoords = { x: containerWidth * 0.01, y: containerHeight * 0.55, width: containerWidth * 0.55, height: containerHeight * 0.44, label: 'Address' };
  
      handleOCR(selectedImage, icCoords);
      handleOCR(selectedImage, nameCoords);
      handleOCR(selectedImage, addressCoords);
  
    });

    if (selectedImage && containerRef.current && props.scan) {
      processImage();
    }
  }, [selectedImage, props.scan]);
  
  useEffect(() => {
    if (ocrComplete.ic && ocrComplete.name && ocrComplete.address && isProcessing) {
      props.onExtractData({ icNumber, name, address });
      setIsProcessing(false);
    }
  }, [ocrComplete, icNumber, name, address, props, isProcessing]);

  return (
      <div className={className} ref={containerRef} >
        <input className={"app-id-scan__button"} type="file" accept="image/*" onChange={handleImageUpload} />

        {
          selectedImage ? (<img src={selectedImage} alt="Selected" className="app-id-scan__frontImage" />) 
          : 
          (<p className="app-id-scan__placeholder">{props.placeholder}</p>)
        }

      </div>
  );
};

// Define PropTypes for the component
TextRecognition.propTypes = {
  name: PropTypes.string,
  icNumber: PropTypes.string,
  address: PropTypes.string,
  outline: PropTypes.bool,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onExtractData: PropTypes.func,
  scan: PropTypes.bool.isRequired,
  error: PropTypes.string,
};

export default TextRecognition;