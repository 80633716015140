const formatInputString = (event) => {
	const value = event.target.value.toUpperCase();
	const allowedRegex = /^[A-Za-z\s]+$/; // Allow letters, numbers, and spaces

	let sanitizedValue = "";

	for (const char of value) {
		// Check if adding the character keeps the string within 100 characters
		if ((sanitizedValue + char).length > 100) break;
		// Check if the character is allowed
		if (allowedRegex.test(sanitizedValue + char)) {
			sanitizedValue += char;
		}
	}

	event.target.value = sanitizedValue;

	return event;
};

export default formatInputString;