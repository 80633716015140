import React, { useEffect, useRef, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import pathnames from "routes/pathnames";
import AppButton from "components/app-button";
import { resetMotor } from "store/slices/motor";
import logo from "assets/images/pa_logo.png";
import AppInput from "components/app-input";
import referralIcon from "assets/images/pages/page-summary/referral-icon.svg";
import { setReferralCode } from "store/slices/form";

const PageHome = () => {
	const timer = useRef();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const location = useLocation();
	const urlParams = new URLSearchParams(location.search);
	const referralCode = urlParams.get("myReferralCode");
	const form = useSelector((state) => state.form);

	const initialValues = useMemo(() => {
		const info = form?.personalForm;

		let payload = {
			referralCode: referralCode || "",
		};

		if (info) {

			if (info.referralCode) payload.referralCode = info.referralCode;
		}

		return payload;
	}, [form, referralCode]);

	const onHandleNavigateNext = () => {
		if (referralCode) {
			navigate(pathnames.paForm);
		} else {
			navigate(pathnames.paForm);
		}
	};

	const formik = useFormik({
		initialValues,
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const onHandleSubmit = (values) => {
		if (referralCode && referralCode !== "null") dispatch(setReferralCode(referralCode));

		onHandleNavigateNext();
	};

	useEffect(() => {
		timer.current = setTimeout(() => {
			dispatch(resetMotor());
		}, 100);

		return () => {
			clearTimeout(timer.current);
		};
	}, [dispatch]);

	return (
		<div className="page-home">
			<div className="home">
				<main className="main">
					<div className="main__content main__content--background">
						<div className="main__logo"> 
							<img src={logo} alt="logo" />
						</div>
					</div>

					<div className="main__content main__content--form">
						<div className="main__form">
							<h1 className="main__title">Insure and secure your Personal Insurance with easycover.my</h1>
							<p className="main__description">Ready to buy an insurance for yourself? </p>
							<p className="main__description">Have it delivered right at your doorstep with easycover.my.</p>
							<p className="main__description">Get FREE quotation now!</p>

							<div className="referral">
									<div className="referral__container">
										<div className="referral__box">
											<div className="referral__wrapper">
												<img className="referral__logo" src={referralIcon} alt="logo" />
												<p className="referral__label">Referral Code</p>
											</div>
								
											<div className="referral__value">
												{/*prettier-ignore*/}
												<AppInput type="text" name="referralCode" label="" placeholder="Enter Referral Code" value={formik.values.referralCode} error={formik.errors.referralCode} touched={formik.touched.referralCode} onChange={formik.handleChange} disabled={formik.isSubmitting || referralCode} />
											</div>
										</div>
									</div>
								</div>

							<div className="main__button-container">
								<AppButton type="submit" label="Let's get started" onClick={onHandleSubmit} />
							</div>
						</div>
					</div>
				</main>
			</div>
		</div>
	);
};

export default PageHome;
