const formatEmailString = (event) => {
	const value = event.target.value;
	const emailRegex = /^[a-zA-Z0-9._@]+$/; // Allow characters valid in an email address

	let sanitizedValue = "";

	for (const char of value) {
		// Check if adding the character keeps the string within 100 characters
		if ((sanitizedValue + char).length > 50) break;
		// Check if the character is allowed
		if (emailRegex.test(sanitizedValue + char)) {
			sanitizedValue += char;
		}
	}

	// Check if the entire string matches a valid email format
	//const isValidEmail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(sanitizedValue);

	event.target.value = sanitizedValue;

	return event;
};

export default formatEmailString;