const ERRORS = {
	REQUIRED: "This field is required.",
	LENGTH: "Use at least 8 characters",
	NUMERIC: "Should contain at least 1 numeric",
	UPPERCASE: "Should contain at least 1 uppercase",
	LOWERCASE: "Should contain at least 1 lowercase",
	SYMBOL: "Should contain at least 1 special character",
	CONFIRM_PASSWORD: "Password confirmation doesn't match.",
	FILE_SIZE: "File Size is too large (maximum 5MB)",
	MININUM_ITEM: "Field must have at least 1 items",
	START_DATE: "Start Date must be before end date",
	START_DATE_EXPIRED: "Start Date must be today or future date",
	INSUFFICIENT_BALANCE: "Insufficient balance",
	END_DATE: "End Date must be after start date",
	EMAIL: "Please provide a valid email",
	ANNOUNCEMENT_IMAGE_SIZE: "File dimension should at least be (400px x 400px)",
	PROFILE_IMAGE_SIZE: "File size not exceeding 5MB and image size not more than 160 pixels by 50 pixels.",
	NRIC: "Please enter your 12 digital NRIC number, e.g. 901010141234",
	MOBILE: "Should contain at least 9 digit",
	POSTCODE: "Invalid postcode",
	MINIMUM_NUM: "Total number need to be more than 0"
};

export default ERRORS;
